<template>
  <div class="home_div">
    <div class="home_ln" v-if="navigationActive == 0">
      <div class="home_ln_1">
        <div class="chat-input">
          <div class="ms" v-if="!isMobile">Shift+Enter输入框内换行</div>
          <textarea
            v-model="value"
            ref="test"
            @keydown.enter="exactA"
            class="textarea_input"
            placeholder="告诉AI你想要它生成的内容"
          ></textarea>
          <div class="button_box">
            <img
              class="fs_button"
              @click="to()"
              src="@/assets/images/new/enter.svg"
            />
          </div>
        </div>
      </div>
      <div class="home_ln_2" v-if="!isMobile">
        <div class="title">
          <img src="@/assets/images/new/tip.png" class="img" />
          提问示例
        </div>
        <div class="list_box" :class="{ heighta: isGs }">
          <div
            class="list"
            v-for="(item, index) in list"
            :key="index"
            style="white-space: pre-wrap"
            @click="value = item.valueArr[item.nub]"
          >
            {{ item.valueArr[item.nub] }}
            <span class="yjsr">一键输入</span>
          </div>
        </div>

        <div class="gdsl" @click="isGs = !isGs">
          {{ isGs ? "收起更多示例" : "展开更多示例"
          }}<i :class="isGs ? 'el-icon-caret-top' : 'el-icon-caret-bottom'"></i>
        </div>
      </div>
      <div class="home_ln_3" v-else>
        <div class="tabs">
          <div
            class="tab"
            :class="{ active: tabActive == index }"
            @click="tabActive = index"
            v-for="(item, index) in list"
            :key="index"
          >
            {{ item.title }}
          </div>
        </div>
        <div
          class="text"
          @click="value = list[tabActive].valueArr[list[tabActive].nub]"
        >
          {{ list[tabActive].valueArr[list[tabActive].nub] }}
        </div>
        <div class="button" @click="listChange()">
          <i class="icon el-icon-refresh-right"></i>换一个
        </div>
      </div>
    </div>
    <div class="home_ln home_Active1" v-if="navigationActive == 1">
      <div class="home_ln_4">
        <div
          class="temp-item"
          @click="appto(item.title)"
          v-for="(item, index) in listA"
          :key="index"
        >
          <div class="temp-icon">
            <img class="temp-img" :src="item.img" />
          </div>
          <div class="temp-title">{{ item.title }}</div>
          <div class="temp-desc">{{ item.desc }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["navigationActive"],
  data() {
    return {
      list: [
        {
          title: "小红书文案",
          nub: 0,
          valueArr: [
            "用小红书里的文案风格，写一个推荐老友记的笔记，需要带标题和正文，正文采用 小红书里女生爱用的口吻，适当插入emoji和话题",
          ],
        },
        {
          title: "做表格",
          nub: 0,
          valueArr: [
            "写一个'跨域中继无人直升机集群项目'项目计划，分8个季度来写，24个月来写，以表格的形式来显示",
          ],
        },
        {
          title: "论文润色",
          nub: 0,
          valueArr: [
            "请用nature communications杂志风格的语言润色并扩展成学术论文形式的结果与讨论：\
            \n\nThe Annexin V-FITC Apoptosis Detection Kit was applied to the cytotoxicity of HP-Ppa NPs, HP-PTX NPs and HP-PP NPs quantitatively. Results showed HP-PP NPs could increase cell apoptosis compared to HP-PTX NPs or HP-Ppa NPs (Fig. 3F). The impact of HP-PTX NPs and HP-Ppa on microtubule and microfilament were tested. The morphology of microtubules and microfilaments were observed under a CLSM. Results showed the microtubules and microfilaments congregated around the cellular cortex in cells treated with HP-PTX NPs and HP-PP NPs, while microtubules and microfilaments were distributed throughout the cytoplasm in control and HP-Ppa NPs treated groups evenly (Fig. 3G, Fig S34). These results revealed HP-PP NPs had strong cytotoxicity to 4T1 cells.",
          ],
        },
        {
          title: "营销话术",
          nub: 0,
          valueArr: [
            "怎么样的推销话术，可以让家长对孩子的学习成绩、技能提升、职业发展感兴趣",
            "写一个燕窝的广告词，体现燕窝的质量好，价格低，食用方便",
          ],
        },
        {
          title: "改风格",
          nub: 0,
          valueArr: [
            "请把以下这段话改成被大众媒体采访时的语言风格：“当代女性的价值已经体会在社会的方方面面了，从伟大的科学家，教育家，政治家都能看见女性的身影。这个问题很有意思，因为它让我思考一个更重要的问题，如何让更多的女性发挥自身的独特潜能，追求自我价值的实现，在社会的各个领域发挥>更多的作用。因为目前社会对女性的要求 - 生养孩子。感觉女性更容易是为了家庭为了孩子，放弃自我追求的那个。我个人最大的感触就是 - 任>何时候都不要放弃对自己梦想的追求。当然不得已的时候，可以按按暂停键。但永远都要有梦想，永远不要放弃对梦想的追求。“",
          ],
        },
      ],
      tabActive: 0,
      isGs: true,
      value: "",
      isMobile: "",
      listA: [
        {
          img: require("@/assets/images/home_ln_4_xhs.jpg"),
          title: "小红书",
          desc: "根据产品特点，帮你生成符合小红书调性的分享文案",
        },
        {
          img: require("@/assets/images/home_ln_4_ljh.jpg"),
          title: "营销文案",
          desc: "戳中用户痛点，打造爆款产品",
        },
        {
          img: require("@/assets/images/home_ln_4_ljh.jpg"),
          title: "列计划",
          desc: "根据简短描述生成具体计划",
        },
        {
          img: require("@/assets/images/home_ln_4_aihh.jpg"),
          title: "新闻稿",
          desc: "列新闻大纲，生成新闻稿",
        },
        // ,{
        //     "img":require("@/assets/images/home_ln_4_aihh.jpg"),
        //     "title":"AI绘画提示词",
        //     "desc":"生成适用于AI绘画的描述词(prompt)"
        // }

        {
          img: require("@/assets/images/home_ln_4_douyin.jpg"),
          title: "短视频文案",
          desc: "快速生成短视频脚本、口播稿",
        },
        {
          img: require("@/assets/images/home_ln_4_douyin.jpg"),
          title: "算命",
          desc: "仅供娱乐",
        },
        // ,{
        //     "img":require("@/assets/images/home_ln_4_tb.jpg"),
        //     "title":"商品评价",
        //     "desc":"这块文案自己放"
        // }
      ],
    };
  },
  mounted() {
    var that = this;
    that.isMobile = navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    //计算实例高度 - 480
    // var clientHeight=document.body.clientHeight-520;
    // var listBox=that.$refs.listBox;
    // var height=0;
    // var iskey=1;
    // listBox.forEach((element,key) => {
    //     height+=element.offsetHeight+20;
    //     if(height<clientHeight){
    //         iskey=key+1
    //     }
    //     // console.log(height)
    // });
    // console.log(iskey)
    // that.isGs=iskey;
    // that.intoGs=iskey;
    // console.log(document.body.clientHeight-480)
  },
  methods: {
    listChange() {
      var that = this;
      console.log(that.list[that.tabActive].valueArr.length);
      console.log(that.list[that.tabActive].nub);
      if (
        that.list[that.tabActive].nub + 1 >=
        that.list[that.tabActive].valueArr.length
      ) {
        that.list[that.tabActive].nub = 0;
      } else {
        that.list[that.tabActive].nub++;
      }
    },
    exactA(e) {
      var that = this;
      var textArea = that.$refs.test;
      var scrollHeight = textArea.scrollHeight;
      if (e.keyCode == 13 && e.ctrlKey) {
        that.value += "\n"; //另起一行换行
        textArea.scrollTo({ top: scrollHeight, behavior: "smooth" });
      } else if (e.keyCode == 13 && e.shiftKey) {
        const start = textArea.selectionStart;
        const end = textArea.selectionEnd;
        that.value = that.value.slice(0, start) + "\n" + that.value.slice(end); //鼠标点击处换行

        this.$nextTick(() => {
          textArea.setSelectionRange(start + 1, start + 1);
          textArea.focus();
        });
        // textArea.scrollTo({ top: scrollHeight, behavior: "smooth" });
        e.preventDefault(); //禁止回车的默认换行
      } else if (e.keyCode == 13) {
        that.to();
        e.preventDefault(); //禁止回车的默认换行
      }
    },
    to() {
      var that = this;
      if (that.value) {
        localStorage.setItem("chatValue", that.value);
        that.$router.push({
          path: "/chat",
        });
      }
    },
    appto(title) {
      var that = this;
      that.$router.push({
        path: "/appchat",
        query: { appTitle: title, navigationActive: 1 },
      });
    },
  },
};
</script>
<style lang="less" scoped></style>
